import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostLayout from "../../../layouts/post-layout.tsx";
export const _frontmatter = {
  "title": "How to setup Next.js, Apollo GraphQL, TypeScript, Styled Components and Server side rendering in 4 simple Steps",
  "path": "/posts/2020-01-02-frontend-boilerplate-ts-next-gql-react",
  "description": "Learn how to create a React SSR boilerplate with Next.js, TypeScript, GraphQL, and Styled Components.",
  "date": "2020-01-02T00:00:00.000Z",
  "tags": ["Boilerplate", "Next.js", "GraphQL", "TypeScript", "SSR"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PostSeoComponent = makeShortcode("PostSeoComponent");
const PostMetaComponent = makeShortcode("PostMetaComponent");
const AlertComponent = makeShortcode("AlertComponent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PostSeoComponent mdxType="PostSeoComponent" />
    <h1>{`How to setup Next.js, Apollo GraphQL, TypeScript, Styled Components and Server side rendering in 4 simple Steps`}</h1>
    <PostMetaComponent mdxType="PostMetaComponent" />
    <AlertComponent level="warning" mdxType="AlertComponent">
This article was published a long time ago. Its content may be outdated and no longer reflects the current state of technology.
    </AlertComponent>
    <p>{`In this Article I will show you how to create a frontend with many of the most powerful web technologies that are there right now.`}</p>
    <p>{`We will be creating a simple job list with data provided by `}<a parentName="p" {...{
        "href": "https://graphql.jobs/"
      }}>{`graphql.jobs`}</a>{`.`}</p>
    <p>{`Before we start let’s get a little bit more familiar with the tech stack we are going to use.`}</p>
    <p>{`TypeScript is an open-source programming language developed and maintained by Microsoft. It is a strict syntactical superset of JavaScript, and adds optional static typing to the language.`}</p>
    <p>{`Next.js is a production ready web framework for creating server side rendered react frontends.`}</p>
    <p>{`Apollo GrpahQL is Apollos implementation of GraphQL, an open-source data query and manipulation language for APIs.`}</p>
    <p>{`Styled Components are one of the new ways to use CSS in modern JavaScript. It is the meant to be a successor of CSS Modules, a way to write CSS that’s scoped to a single component, and not leak to any other element in the page.`}</p>
    <h2>{`What is Server Side Rendering or SSR?`}</h2>
    <p>{`Sever Side Rendering is a technique where you render a client side application (e.g. a single page react app) on the server and ship the fully loaded DOM to the client.`}</p>
    <p>{`This technique gives your page grater SEO power and performance than a traditional single page application (SPA) since clients (web browsers and crawlers) don’t need to execute JavaScript to get a full view of the DOM.`}</p>
    <AlertComponent level="info" mdxType="AlertComponent">
I will use Yarn through out this tutorial. If you are using NPM feel free to replace `yarn add` with `npm install --save`.
    </AlertComponent>
    <h2>{`Step One: Setup Next.js`}</h2>
    <p>{`First, let’s create a new project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`mkdir killer-frontend
cd killer-frontend
yarn init -y
yarn add react react-dom next
mkdir pages
`}</code></pre>
    <p>{`Now, open the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file in the root of your new project and add the following `}<inlineCode parentName="p">{`scripts`}</inlineCode>{` section:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "killer-frontend",
  "version": "1.0.0",
  "main": "index.js",
  "license": "MIT",
  "dependencies": {
    "next": "^9.1.6",
    "react": "^16.12.0",
    "react-dom": "^16.12.0"
  },
  "scripts": {
    "dev": "next",
    "build": "next build",
    "start": "next start"
  }
}
`}</code></pre>
    <p>{`We can now use the commands `}<inlineCode parentName="p">{`yarn dev`}</inlineCode>{`, `}<inlineCode parentName="p">{`yarn build`}</inlineCode>{` and `}<inlineCode parentName="p">{`yarn start`}</inlineCode>{`.`}</p>
    <p>{`In development you probably always want to use `}<inlineCode parentName="p">{`yarn dev`}</inlineCode>{`, since it starts your project in the development mode with pre-enabled hot reload.`}</p>
    <p>{`In production you first have to generate a production build with `}<inlineCode parentName="p">{`yarn build`}</inlineCode>{` and then let it be served by Next.js with `}<inlineCode parentName="p">{`yarn start`}</inlineCode>{`. This method takes more time but will grant you much greater performance.`}</p>
    <p>{`Let’s run `}<inlineCode parentName="p">{`yarn dev`}</inlineCode>{` and visit `}<a parentName="p" {...{
        "href": "http://localhost:3000/"
      }}>{`http://localhost:3000/`}</a>{`. You should see a 404, since we don’t have any pages yet. Don’t worry, we will change that soon.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f866d7aea7d72e28c1e6ff57bb71521c/8698d/s-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.71428571428571%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAn0lEQVQoz7WS2wqDMAxA+/+f42xzUdke9k2dNk06nNKHocNNdgg0kBxSmjpJIklM1UxVs5mWYmUHVa2Jmbmc81uH7VOrS6fLkre0shW1tLIx+Th/kEUkxphSesyM9Z2+kKdpijGO4wdZTlxbTsnzD7Fl6fpiPQ7gzMrvk6/c9dQx8H0YGBmBbsQA5AM1HpsWL55aTwgUAkKYEwrUIzPQE0ZlupwJ+1LHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot 1",
            "title": "Screenshot 1",
            "src": "/static/f866d7aea7d72e28c1e6ff57bb71521c/8c557/s-1.png",
            "srcSet": ["/static/f866d7aea7d72e28c1e6ff57bb71521c/4edbd/s-1.png 175w", "/static/f866d7aea7d72e28c1e6ff57bb71521c/13ae7/s-1.png 350w", "/static/f866d7aea7d72e28c1e6ff57bb71521c/8c557/s-1.png 700w", "/static/f866d7aea7d72e28c1e6ff57bb71521c/e996b/s-1.png 1050w", "/static/f866d7aea7d72e28c1e6ff57bb71521c/8698d/s-1.png 1239w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Step Two: Add TypeScript`}</h2>
    <p>{`I know, this step sound like pain, but adding TypeScript to a Next.js project is incredibly straight forward.`}</p>
    <p>{`Stop your Next.js dev server and create an empty `}<inlineCode parentName="p">{`tsconfig.json`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`touch tsconfig.json
`}</code></pre>
    <p>{`Then start the dev server. Next.js will now recognize that you want to use TypeScript and tells you what to do next.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/601e5420fc1b523b8c3bc062959839c7/b5dee/s-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.71428571428572%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxElEQVQY02XNTW+DMAyA4SD1kNGkOE0cOR9AV05I/B+kFdg62k698P+PVYY2Teuj1xdLltn1/n7+mqeP6zDN0/n2OV/GcXxbnU5r/wzD0Pf9sixMSNhyvsu3qZc832zYj+y7Z1mW1jFG5olc4kMIaWKo6to5R9buATSAVmoPCv5QSgkhuq5j3oXq9VAfj4emqZvGl6UvKwpRE1lKDKIxGg0aYxARAIQQnPO2bZl2Gp21VFkq0UaldLGTv0+KohBPpJTr8QNK3SYLCNiwoAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot 2",
            "title": "Screenshot 2",
            "src": "/static/601e5420fc1b523b8c3bc062959839c7/8c557/s-2.png",
            "srcSet": ["/static/601e5420fc1b523b8c3bc062959839c7/4edbd/s-2.png 175w", "/static/601e5420fc1b523b8c3bc062959839c7/13ae7/s-2.png 350w", "/static/601e5420fc1b523b8c3bc062959839c7/8c557/s-2.png 700w", "/static/601e5420fc1b523b8c3bc062959839c7/e996b/s-2.png 1050w", "/static/601e5420fc1b523b8c3bc062959839c7/b5dee/s-2.png 1237w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let’s follow the instructions and add the missing packages:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add --dev typescript @types/react @types/node
`}</code></pre>
    <p>{`Note the `}<inlineCode parentName="p">{`--dev`}</inlineCode>{` flag to install these packages as devdependencies.`}</p>
    <p>{`We are ready to go! Let’s create a simple Homepage. Go ahead and create a `}<inlineCode parentName="p">{`index.tsx`}</inlineCode>{` file in the `}<inlineCode parentName="p">{`/pages`}</inlineCode>{` directory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`touch pages/index.tsx
`}</code></pre>
    <p>{`And:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React from "react"
const Index = () => (
  <div>
    <h1>GraphQL Job Board</h1>
    <p>A list of open GraphQL jobs.</p>
  </div>
)
export default Index
`}</code></pre>
    <p>{`Start your server and visit `}<a parentName="p" {...{
        "href": "http://localhost:3000/"
      }}>{`http://localhost:3000/`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/893406ea4ad8d176a0271c7245187038/8698d/s-3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.71428571428571%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAeklEQVQoz+XRsQrEIAwG4Lz/02jvdZzq4KBiHSUmKdWeHJzQozf2I4OQ5CcgbCmFEHLOwfstJWYSYZnhpj/pwGDX1RhjrXXOxRjH0MyIOOOglEJEtdbPeGaZ1WidACt+XfUrQES565HL/avuAfkDKKVezXJFa63edLMDpDm3TkboHjoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot 3",
            "title": "Screenshot 3",
            "src": "/static/893406ea4ad8d176a0271c7245187038/8c557/s-3.png",
            "srcSet": ["/static/893406ea4ad8d176a0271c7245187038/4edbd/s-3.png 175w", "/static/893406ea4ad8d176a0271c7245187038/13ae7/s-3.png 350w", "/static/893406ea4ad8d176a0271c7245187038/8c557/s-3.png 700w", "/static/893406ea4ad8d176a0271c7245187038/e996b/s-3.png 1050w", "/static/893406ea4ad8d176a0271c7245187038/8698d/s-3.png 1239w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`There you have it. Your first page! 🍻`}</p>
    <h2>{`Step Three: Setup Styled Components`}</h2>
    <p>{`To setup Styled Components we first have to add some packages:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add styled-components
yarn add --dev @types/styled-components babel-plugin-styled-components
`}</code></pre>
    <p>{`Since Next.js uses babel internally we have to configure babel to extract and transpile our Styled Components template code from our components.`}</p>
    <p>{`Therefore we have to create a `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{` file in our project root.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`touch .babelrc
`}</code></pre>
    <p>{`Then add the following babel config:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "presets": [
    "next/babel"
  ],
  "plugins": [
    [
      "styled-components",
      {
        "ssr": true,
        "displayName": true,
        "preprocess": false
      }
    ]
  ]
}
`}</code></pre>
    <p>{`This babel plugin adds a unique identifier to each styled component to avoid checksum mismatches. This is great for stability but could get uns into warning-hell, since it could happen that the same class gets a different name on the server than on the client. Fortunately we can avoid this problem by setting `}<inlineCode parentName="p">{`ssr`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`displayName`}</inlineCode>{` option adds the component name to the generated CSS class to make it easier for debugging.`}</p>
    <p><inlineCode parentName="p">{`preprocess`}</inlineCode>{` is an experimental feature that we don’t need in our case.`}</p>
    <p>{`Now we are ready to render our components on the server side. In order to do that we have to create a `}<inlineCode parentName="p">{`_document.tsx`}</inlineCode>{` file in the `}<inlineCode parentName="p">{`pages/`}</inlineCode>{` directory.`}</p>
    <p>{`Feel free to read more about Next.js `}<a parentName="p" {...{
        "href": "https://nextjs.org/docs/advanced-features/custom-document"
      }}>{`magic files`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`touch pages/_document.tsx
`}</code></pre>
    <p>{`And add the code that will handle the server sided rendering of our styled components:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx",
        "metastring": "title=_document.tsx showLineNumbers",
        "title": "_document.tsx",
        "showLineNumbers": true
      }}>{`import React from "react"
import Document, { Head, Main, NextScript } from "next/document"
import { ServerStyleSheet } from "styled-components"

export default class MyDocument extends Document<any> {
  static async getInitialProps(ctx) {
    const sheet = new ServerStyleSheet()
    const originalRenderPage = ctx.renderPage

    try {
      // wraps the collectStyles provider around our <App />.
      ctx.renderPage = () =>
        originalRenderPage({
          enhanceApp: App => props => sheet.collectStyles(<App {...props} />),
        })

      // extract the initial props that may be present.
      const initialProps = await Document.getInitialProps(ctx)
      
      // returning the original props together with our styled components.
      return {
        ...initialProps,
        styles: (
          <>
            {initialProps.styles}
            {sheet.getStyleElement()}
          </>
        ),
      }
    } finally {
      sheet.seal()
    }
  }

  render() {
    return (
      <html>
        <Head>
          {this.props.styleTags /*rendering the actually stylesheet*/}
        </Head>
        <body>
          <Main />
          <NextScript />
        </body>
      </html>
    )
  }
}
`}</code></pre>
    <p>{`Let’s create a global style to test our configuration:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx",
        "metastring": "title=_app.tsx showLineNumbers",
        "title": "_app.tsx",
        "showLineNumbers": true
      }}>{`import React from "react"
import App from "next/app"
import Head from "next/head"
import { ThemeProvider, createGlobalStyle } from "styled-components"

export interface ITheme {
  niceBlack: string;
}

export interface IThemeWrapper {
  theme: ITheme;
}

export const theme: ITheme = {
  niceBlack: "#001F3F",
}

const GlobalStyle = createGlobalStyle<IThemeWrapper>\`
  body {
    margin: 0 auto;
    color: \${props => props.theme.niceBlack}; 
  }
\`

export default class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props

    return (
      <React.Fragment>
        <Head>
          <title>GraphQL Job Board</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Component {...pageProps} />
        </ThemeProvider>
      </React.Fragment>
    )
  }
}
`}</code></pre>
    <p>{`If we restart our server we can verify that the stylesheet is being rendered on the server side:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0229f72f1dffe4b1cc8535448f69aaa7/8698d/s-4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.71428571428571%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAu0lEQVQoz+3N0YrCMBAF0Pz/p7Sf0EKhBLb9B5cttavrQ7dNzKQTJ5kRCwsKIiu+ergPA5fLKAfB9ge76cya+bObN1/H7geHPW6H8L1bhgH7nk8hJY5EIhIjh5CIWHmP3pIdFzOi/Q1mXI4Tuum0GI+z9ZMFY2CewEEICZHAMYA4Jx5ExRjlv/jquGQds/CtteKb3PPU5/dYRBERPyn9UfICpbX+eKhpmrqui6Ioy1Jr3bZtVVV5nmdZdgZIArhyKKNKNwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot 4",
            "title": "Screenshot 4",
            "src": "/static/0229f72f1dffe4b1cc8535448f69aaa7/8c557/s-4.png",
            "srcSet": ["/static/0229f72f1dffe4b1cc8535448f69aaa7/4edbd/s-4.png 175w", "/static/0229f72f1dffe4b1cc8535448f69aaa7/13ae7/s-4.png 350w", "/static/0229f72f1dffe4b1cc8535448f69aaa7/8c557/s-4.png 700w", "/static/0229f72f1dffe4b1cc8535448f69aaa7/e996b/s-4.png 1050w", "/static/0229f72f1dffe4b1cc8535448f69aaa7/8698d/s-4.png 1239w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`And there we go. You have successfully implemented Styled Components with SSR! 👌`}</p>
    <h2>{`Step Four: Setup Apollo GraphQL`}</h2>
    <p>{`I will assume that you know the fundamentals of GraphQL. If not, I highly recommend you reading `}<a parentName="p" {...{
        "href": "https://hackernoon.com/3-minute-introduction-to-graphql-2c4e28ed528"
      }}>{`this`}</a>{` article.`}</p>
    <p>{`We will be using Apollos implementation of GraphQL since it’s well maintained and battle proofed.`}</p>
    <h3>{`The concept`}</h3>
    <p>{`In order to keep our code quality clean we will be writing our queries and mutations in the pages of our Next.js project only and pass the data we need down to our components as props.`}</p>
    <p>{`We will use the `}<inlineCode parentName="p">{`useQuery`}</inlineCode>{` hook from the `}<inlineCode parentName="p">{`@apollo/react-hooks`}</inlineCode>{` package to write our queries. This makes implementing server side rendering a no-brainer and works very well in our react context.`}</p>
    <p>{`If a client (e.g web browser) makes a GET request to one of our pages the idea is that we will evaluate all queries that need to be run on that page and fire them on the server side. We take the data returned by those queries and re hydrate the DOM with it. Then we send the fully re hydrated DOM back to the client.`}</p>
    <p>{`Since this isn’t a GraphQL Server tutorial we will be using a public GraphQL endpoint to have some sample data. In our case this will be `}<a parentName="p" {...{
        "href": "https://api.graphql.jobs/"
      }}>{`https://api.graphql.jobs/`}</a>{`.`}</p>
    <h3>{`The implementation`}</h3>
    <p>{`Let’s start by installing all the packages we need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add @apollo/react-hooks apollo-boost graphql node-fetch next-with-apollo
`}</code></pre>
    <p>{`next-with-apollo is a provider that will handle the re hydration in our Next.js context. If you want you could create your own implementation but there is no need to reinvent the wheel ;)`}</p>
    <p>{`We can now create our Apollo client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx",
        "metastring": "title=hooks/withApollo.ts showLineNumbers",
        "title": "hooks/withApollo.ts",
        "showLineNumbers": true
      }}>{`import withApollo from "next-with-apollo"
import ApolloClient, { InMemoryCache } from "apollo-boost"
 
export default withApollo(
  ({ initialState }) =>
    new ApolloClient({
      uri: "https://api.graphql.jobs/",
      cache: new InMemoryCache().restore(initialState || {})
    })
)
`}</code></pre>
    <p><inlineCode parentName="p">{`initialState`}</inlineCode>{` is the accumulated data returned by the queries. It will be passed to the cache as the initial data.`}</p>
    <p>{`After that we have to make some additions to the `}<inlineCode parentName="p">{`_app.tsx`}</inlineCode>{` file to add our own `}<inlineCode parentName="p">{`withApollo`}</inlineCode>{` provider:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx",
        "metastring": "title=_app.tsx showLineNumbers",
        "title": "_app.tsx",
        "showLineNumbers": true
      }}>{`import React from "react"
import App from "next/app"
import Head from "next/head"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import { ApolloProvider } from "@apollo/react-hooks"
import withApollo from "../hooks/withApollo"
import { ApolloClient, NormalizedCacheObject } from "apollo-boost"

export interface ITheme {
  niceBlack: string;
}

export interface IThemeWrapper {
  theme: ITheme;
}

export const theme: ITheme = {
  niceBlack: "#001F3F",
}

const GlobalStyle = createGlobalStyle<IThemeWrapper>\`
  body {
    margin: 0 auto;
    color: \${props => props.theme.niceBlack}; 
  }
\`

// since "apollo" isn't a native Next.js prop we have to declare it's type.
interface IProps {
  apollo: ApolloClient<NormalizedCacheObject>;
}

// adds our custom props interface to the generic App base class.
class MyApp extends App<IProps> {
  render() {
    // instead of creating a client here, we use the rehydrated apollo client provided by our own withApollo provider. 
    const { Component, pageProps, apollo } = this.props

    return (
      <React.Fragment>
        <Head>
          <title>GraphQL Job Board</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        {/* adds the apollo provider to provide it's children with the apollo scope. */}
        <ApolloProvider client={apollo}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Component {...pageProps} />
          </ThemeProvider>
        </ApolloProvider>
      </React.Fragment>
    )
  }
}

// before exporting our App we wrapp it with our own withApollo provider to have access to the our rehydrated apollo client.
export default withApollo(MyApp)
`}</code></pre>
    <p>{`All changes are marked with comments.`}</p>
    <p>{`We can now write a query to get all jobs from our endpoint. The best place to do this is in the `}<inlineCode parentName="p">{`pages/index.tsx`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx",
        "metastring": "title=index.tsx showLineNumbers",
        "title": "index.tsx",
        "showLineNumbers": true
      }}>{`import React from "react"
import JobList from "../components/JobList"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"

const Index = () => {
  // our query that defines the attributes we want to get.
  const JOBS_QUERY = gql\`
    query {
      jobs {
        id
        title
        applyUrl
        company {
          name
        }
      }
    }
  \`

  // the hook that calls the query.
  const jobs = useQuery(JOBS_QUERY)

  return (
    <div>
      <h1>GraphQL Job Board</h1>
      <p>A list of open GraphQL jobs.</p>
      <JobList jobs={jobs?.data?.jobs || []} />
    </div>
  )
}

export default Index
`}</code></pre>
    <p>{`As you can see we pass the returned jobs or an empty array as a prop to our JobList component since `}<inlineCode parentName="p">{`jobs.data.jobs`}</inlineCode>{` isn’t always defined.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Tip: “?” is a `}<a parentName="p" {...{
          "href": "https://github.com/microsoft/TypeScript/issues/16"
        }}>{`safe navigation operator`}</a>{`. We have to use it here because `}<inlineCode parentName="p">{`jobs.data`}</inlineCode>{` can be undefined at a certain point in time.`}</p>
    </blockquote>
    <p>{`The last thing left to do is to build our JobList component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx",
        "metastring": "title=JobList.tsx",
        "title": "JobList.tsx"
      }}>{`import React from "react"
import styled from "styled-components"

// this interface defines the shape of the data returned by the jobs query.
export interface IJob {
  id: string;
  applyUrl: string;
  title: string;
  company: {
    name: string;
  }
}

interface IProps {
  jobs: IJob[];
}

const List = styled.ul\`\`
const ListItem = styled.li\`
  margin-bottom: .5rem;
\`

const JobList = ({ jobs }: IProps) => {
  const listItems = jobs.map((job) => {
    return (
      <ListItem key={job.id}>
        {job.title} by {job.company.name} [<a href={job.applyUrl} target="_blank">Apply</a>]
      </ListItem>
    )
  })
  
  return (
    <List>
      {listItems}
    </List>
  )
}

export default JobList
`}</code></pre>
    <p>{`And here is the final result:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/003c67b10ef626cfae21fab545a5f53e/8698d/s-5.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.71428571428571%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNklEQVQoz42SXW+CMBSG+/9/iRdORUFZ9jOWLBlzfJS2FFra8rGgYOkCMm80kyfn4lyc5/RtUyBlwYUqq5rlsqzqvtfG9OYR/cjUaN2eWxBAcowwSrKE5bKob0OPuK0YmqZpgFQFpgxRxoViQsRYcn7Wepi4q2nF1b7KJcQpJCnEKUlZiBgiVV1fzDNOpxNgXHh+/OnDY4h9iN4/eJ5fxnhPZK01UEU5HpthmvmhyvPOTLnmyGqQh8CQY9ya2QxyxoUXxF8B8vzY8ylEVdP0c2UhVYRoiGhE6HdEw6isf9qu07PuLFQRJxmaipE0DSIlRDv8g3/pug7IojpGBBIWIOrHFCY8jGulzJwHB669Xy03qxfrsLWXy81iYb85h63l2vvXreNatms7B2dkt9tt/rAsa71e/wL4oKdViLwv0gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot 5",
            "title": "Screenshot 5",
            "src": "/static/003c67b10ef626cfae21fab545a5f53e/8c557/s-5.png",
            "srcSet": ["/static/003c67b10ef626cfae21fab545a5f53e/4edbd/s-5.png 175w", "/static/003c67b10ef626cfae21fab545a5f53e/13ae7/s-5.png 350w", "/static/003c67b10ef626cfae21fab545a5f53e/8c557/s-5.png 700w", "/static/003c67b10ef626cfae21fab545a5f53e/e996b/s-5.png 1050w", "/static/003c67b10ef626cfae21fab545a5f53e/8698d/s-5.png 1239w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`If we take a look at the source code we can see that everything was re hydrated on the server side:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3843365b2bbd7d07cb775926d924d3f4/8698d/s-6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.71428571428571%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAAArElEQVQoz9XOzYrDMAwEYL//qyTNGtv9odC3SlzLo5GWJu2pe1iaUz8GLASDHEgFQNJUFZ18PEr1N2Y043M00izcl7u0LuJbevfWKCKtNSigaCLSBUCtrVafZ5tnr9WXxUOXx1nS19gWXVe24osqVR1wwLYE1T9++E9fW8aOMvaUu3R+KvgO4ZLLuZxSTLfTueRjTuVajimVGMsh5kPMPzHF1TRNw8s4jsMw/AJqNLgIjUn16wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot 6",
            "title": "Screenshot 6",
            "src": "/static/3843365b2bbd7d07cb775926d924d3f4/8c557/s-6.png",
            "srcSet": ["/static/3843365b2bbd7d07cb775926d924d3f4/4edbd/s-6.png 175w", "/static/3843365b2bbd7d07cb775926d924d3f4/13ae7/s-6.png 350w", "/static/3843365b2bbd7d07cb775926d924d3f4/8c557/s-6.png 700w", "/static/3843365b2bbd7d07cb775926d924d3f4/e996b/s-6.png 1050w", "/static/3843365b2bbd7d07cb775926d924d3f4/8698d/s-6.png 1239w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`And that’s it. You have created a powerful frontend with many of the most powerful web technologies that are on the market right now. 🎉`}</p>
    <p>{`Source code: `}<a parentName="p" {...{
        "href": "https://github.com/maxbause/next-graphql-styled-components-ts-boilerplate"
      }}>{`https://github.com/maxbause/next-graphql-styled-components-ts-boilerplate`}</a></p>
    <p>{`Cheers, Max.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      